<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Address
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="customer-form">
          <v-text-field
            label="Address Line 1"
            v-model="fields.current_address_line1"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('current_address_line1')"
            :error-messages="errors['current_address_line1']"
          ></v-text-field>
          <v-text-field
            label="Address Line 2"
            v-model="fields.current_address_line2"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('current_address_line2')"
            :error-messages="errors['current_address_line2']"
          ></v-text-field>
          <v-text-field
            label="City/Region"
            v-model="fields.current_address_city_region"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('current_address_city_region')"
            :error-messages="errors['current_address_city_region']"
          ></v-text-field>
          <v-text-field
            label="Postcode/Zip Code"
            v-model="fields.current_address_postcode"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('current_address_postcode')"
            :error-messages="errors['current_address_postcode']"
          ></v-text-field>
          <v-autocomplete
            label="Country *"
            v-model="fields.current_address_country"
            :items="availableCountries"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('current_address_country')"
            :error-messages="errors['current_address_country']"
          ></v-autocomplete>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="customer-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      form: {},
      fields: {
        form_title: "current_address",
        current_address_line1: null,
        current_address_line2: null,
        current_address_city_region: null,
        current_address_postcode: null,
        current_address_country: null,
      },
      errors: {},
    };
  },

  computed: {
    availableCountries() {
      return Object.values(countries.getNames("en"));
    },
  },

  methods: {
    open(form = null) {
      if (form !== null) {
        this.form = form;
        this.isEditing = true;
        this.fields.current_address_line1 = form.current_address_line1;
        this.fields.current_address_line2 = form.current_address_line2;
        this.fields.current_address_city_region =
          form.current_address_city_region;
        this.fields.current_address_postcode = form.current_address_postcode;
        this.fields.current_address_country = form.current_address_country;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.formId = this.form.application_id;
      }

      this.$store
        .dispatch("easylets/applicationformsStore/saveForm", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.form = {};
      this.fields = {
        current_address_line1: null,
        current_address_line2: null,
        current_address_city_region: null,
        current_address_postcode: null,
        current_address_country: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
