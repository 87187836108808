<template>
  <div>
    <v-card outlined class="mb-6">
      <v-card-subtitle class="d-flex justify-start align-center grey lighten-5">
        <div class="flex-grow-1">Details</div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              depressed
              color="blue lighten-4 blue--text"
              v-on="on"
              @click="$refs.addressDialog.open(form)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit Customer's Information</span>
        </v-tooltip>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr>
              <th>Address Line 1</th>
              <td>{{ form.current_address_line1 }}</td>
            </tr>
            <tr>
              <th>Address Line 2</th>
              <td>{{ form.current_address_line2 }}</td>
            </tr>
            <tr>
              <th>City/Region</th>
              <td>{{ form.current_address_city_region }}</td>
            </tr>
            <tr>
              <th>Postcode/Zip Code</th>
              <td>
                {{ form.current_address_postcode }}
              </td>
            </tr>
            <tr>
              <th>Country</th>
              <td>
                {{ form.current_address_country }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <address-dialog ref="addressDialog" />
  </div>
</template>

<script>
import AddressDialog from "./components/AddressDialog.vue";

export default {
  components: {
    AddressDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Forms",
          disabled: false,
          to: { name: "module-easylets-applicationforms" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    form() {
      return this.$store.getters["easylets/applicationformsStore/get"];
    },
  },
};
</script>
